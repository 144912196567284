import Vue from 'vue'
import VueRouter from "vue-router";

// import wuChuang from 'views/wuchuang/wuchuang'
// import SiFa from 'views/siFa/SiFa'
// import purchase from "views/purchase/purchase";

const wuChuang = () => import('views/wuchuang/wuchuang')
const SiFa = () => import('views/siFa/SiFa')
const HPV = () => import('views/HPV/HPV')


Vue.use(VueRouter)

const routes = [
    {
        path: '/wuchuang',
        component: wuChuang,
        meta: {
            title: "无创亲子|孕期亲子检测|个人亲子鉴定|胎儿亲子鉴定|亲缘关系鉴定|血缘亲子鉴定|隐私亲子鉴定|DNA亲子鉴定|DNA鉴定"
        }
    },
    {
        path: '/sifa',
        component: SiFa,
        meta: {
            title: "司法亲子鉴定|孕期亲子检测|个人亲子鉴定|胎儿亲子鉴定|亲缘关系鉴定|血缘亲子鉴定|隐私亲子鉴定|DNA亲子鉴定|DNA鉴定"
        }
    },
    {
        path: '/HPV',
        component: HPV,
        meta: {
            title: "HPV分型基因检测"
        }
    }
    // {
    //     path: '/purchase',
    //     component: purchase
    // }
]
const router = new VueRouter({
    routes,
})

export default router