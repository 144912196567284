import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import VueLazyLoad from "vue-lazyload"
import VueWechatTitle from "vue-wechat-title"

Vue.config.productionTip = false

Vue.use(VueLazyLoad)
Vue.use(VueWechatTitle)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
